<template>
  <div class='game-loader' v-if='game'>
    <div class='cat-loading'>
      <img class='loading-cat' src='https://cdn.s777.club/web/cat.png'>
      <span>Loading</span>
    </div>
    <div v-if='!$isMobile()'>
      <vue-friendly-iframe v-if='gameLaunch && gameLaunchURL' class='game-iframe' :src='gameLaunchURL'></vue-friendly-iframe>
      <CButton shape='pill' color='success' @click='closeGame' v-if='gameLaunch' class='game-close-btn'>CLOSE</CButton>
      <h4 v-if='gameLaunchName' class='game-launch-header'>
        Now Playing : {{gameLaunchName}} <span v-if='!isLoggedIn'>(Demo Mode)</span>
      </h4>
    </div>

  </div>
</template>

<script>
import apiGames from '@/api/games'
import apiAccount from '@/api/account'
import { EventBus } from '@/event-bus.js'

export default {
  name: 'TheGameLoader',
  computed: {
    isLoggedIn () {
      return this.$store.state.isLoggedIn
    },
    clientType () {
      return this.$isMobile() ? 'mobile' : 'desktop'
    },
    returnURL () {
      return window.location.href
    }
  },
  data () {
    return {
      isLoading: false,
      game: null,
      gameLaunch: false,
      gameLaunchURL: null,
      gameLaunchName: null
    }
  },
  created () {
    EventBus.$on('launchGame', (game) => {
      this.game = game
      this.gameLaunch = true
      this.gameLaunchName = game.name
      this.toggleBodyClass('addClass', 'noscroll');
      this.loadGame(game)
    })
  },
  methods: {
    async loadGame(game) {

      var gameLaunchURL = null

      if(game.provider == 'BG'){
        gameLaunchURL = await this.getBGLink()
      }else if(game.provider == 'FK'){
        gameLaunchURL = await this.getFKLink()
      }else if(game.provider == 'JK'){
        gameLaunchURL = await this.getJKLink()
      }else if(game.provider == 'PS'){
        gameLaunchURL = await this.getPSLink()
      }else if(game.provider == 'OP'){
        gameLaunchURL = await this.getOPLink()
      }else if(game.provider == 'KG'){
        gameLaunchURL = await this.getKGLink()
      }else if(game.provider == 'VP'){
        gameLaunchURL = await this.getVPLink()
      }else{
        gameLaunchURL = null
      }

      if(!gameLaunchURL || gameLaunchURL === '' || gameLaunchURL === null){
        this.$toast.error('Error loading game. Please try again later.', { position: 'top-center', timeout: 5000, hideProgressBar: true })
        this.closeGame()
      }

      this.gameLaunchURL = gameLaunchURL
      if(this.$isMobile()){
        if(!this.isLoggedIn) alert('Playing in demo mode, please login or signup to play for real money.')
        window.location = this.gameLaunchURL
      }

    },
    closeGame() {
      // Playstar Fish
      if(this.isLoggedIn && this.game.provider == 'PS' && this.game.type == 'FTB'){
        this.gameLaunch = false
        this.gameLaunchURL = null
        this.gameLaunchName = null
        apiGames.playstarCloseFTBGame().then(response => {
          this.game = null
          this.toggleBodyClass('removeClass', 'noscroll');
          EventBus.$emit('closeGame')
        }).catch((err) => {
          console.log(err)
        })
      }else if(this.isLoggedIn && this.game.provider == 'VP'){
        this.gameLaunch = false
        this.gameLaunchURL = null
        this.gameLaunchName = null
        apiGames.vpowerCloseGame().then(response => {
          this.game = null
          this.toggleBodyClass('removeClass', 'noscroll');
          EventBus.$emit('closeGame')
        }).catch((err) => {
          console.log(err)
        })
      }else{
        this.game = null
        this.gameLaunch = false
        this.gameLaunchURL = null
        this.gameLaunchName = null
        this.toggleBodyClass('removeClass', 'noscroll');
        EventBus.$emit('closeGame')
      }
    },
    toggleBodyClass(addRemoveClass, className) {
      //window.scrollTo(0,0)
      const el = document.body
      if (addRemoveClass === 'addClass') {
        el.classList.add(className)
      } else {
        el.classList.remove(className)
      }
    },
    async getBGLink(){
      if(this.isLoggedIn){
        const response = await apiGames.launchBgamingLiveGame(this.game.providerID, this.clientType, this.returnURL)
        if(!response){ return '' }
        return response.data.data.launch_options.game_url
      }else{
        const response = await apiGames.launchBgamingDemoGame(this.game.providerID, this.clientType, this.returnURL)
        if(!response){ return '' }
        return response.data.data.launch_options.game_url
      }
    },
    async getFKLink(){
      if(this.isLoggedIn){
        const response = await apiGames.launchFunkyGame(this.game.providerID, window.localStorage.getItem('fkToken'))
        if(!response){ return '' }
        return response.data.data.gameUrl + '?token=' + response.data.data.token + '&redirectUrl=' + encodeURIComponent(this.returnURL)
      }else{
        return this.game.data.demoGameUrl
      }
    },
    async getJKLink(){
      if(this.isLoggedIn){
        const response = await apiGames.launchJokerLiveGame(this.game.providerID, this.returnURL, this.clientType)
        if(!response){ return '' }
        return response.data.url
      }else{
        const response = await apiGames.launchJokerDemoGame(this.game.providerID, this.returnURL, this.clientType)
        if(!response){ return '' }
        return response.data.url
      }
    },
    async getKGLink(){
      if(this.isLoggedIn){
        const response = await apiGames.launchKagaLiveGame(this.game.providerID, this.returnURL, this.clientType)
        if(!response){ return '' }
        return response.data.url
      }else{
        const response = await apiGames.launchKagaDemoGame(this.game.providerID, this.returnURL, this.clientType)
        if(!response){ return '' }
        return response.data.url
      }
    },
    async getOPLink(){
      if(this.isLoggedIn){
        const response = await apiGames.launchOnlyplayLiveGame(this.game.providerID, this.returnURL)
        if(!response){ return '' }
        return response.data.data.url
      }else{
        const response = await apiGames.launchOnlyplayDemoGame(this.game.providerID, this.returnURL)
        if(!response){ return '' }
        return response.data.data.url
      }
    },
    async getPSLink(){
      if(this.isLoggedIn){
        const response = await apiGames.launchPlaystarLiveGame(this.game.providerID, this.returnURL)
        if(!response){ return '' }
        return response.data.url
      }else{
        const response = await apiGames.launchPlaystarDemoGame(this.game.providerID, this.returnURL)
        if(!response){ return '' }
        return response.data.url
      }
    },
    async getVPLink(){
      if(this.isLoggedIn){
        const response = await apiGames.launchVpowerLiveGame(this.game.providerID, this.returnURL)
        if(!response){ return '' }
        return response.data.data.url
      }else{
        const response = await apiGames.launchVpowerDemoGame(this.game.providerID, this.returnURL)
        if(!response){ return '' }
        return response.data.data.url
      }
    }
  }
}
</script>

<template>
  <div class='footer'>

    <CContainer fluid>
      <CRow :gutters='false'>
        <CCol md='3' sm='3' xs='12'>
          <span class='logo-yellow'>super</span>
        </CCol>
        <CCol md='2' sm='3' xs='12'>
          <h4 class='accordion-header' @click='toggle($event)'>Games</h4>
          <div class='accordion-content'>
            <ul>
              <li><CLink to='/games/slots'>Slots</CLink></li>
              <li><CLink to='/games/fish-tables'>Fish Table</CLink></li>
              <li><CLink to='/games/sweepstakes'>Sweepstakes</CLink></li>
              <li><CLink to='/games/arcade'>Arcade</CLink></li>
              <li><CLink to='/games/e-casino'>E-Casino</CLink></li>
              <li><CLink to='/games/all'>Just-In</CLink></li>
              <li><CLink to='/games/all'>Trending</CLink></li>
            </ul>
          </div>
        </CCol>
        <CCol md='2' sm='3' xs='12'>
          <h4 class='accordion-header' @click='toggle($event)'>Promotions</h4>
          <div class='accordion-content'>
            <ul>
              <li><CLink to='/games/promotions'>Welcome Bonus</CLink></li>
              <li><CLink to='/games/promotions'>High Rollers</CLink></li>
              <li><CLink to='/faq'>F.A.Q</CLink></li>
            </ul>
          </div>
        </CCol>
        <CCol md='2' sm='3' xs='12' @click='toggle($event)'>
          <h4 class='accordion-header'>Support</h4>
          <div class='accordion-content'>
            <ul>
              <li><CLink href='mailto:admin@s777.club'>Email Us</CLink></li>
              <li><CLink @click='openSupportModal'>Submit Ticket</CLink></li>
              <li><CLink href='https://facebook.com/super777club' target='_blank'>Messenger</CLink></li>
            </ul>
          </div>
        </CCol>
        <CCol md='3' sm='12' xs='12'>
          <div class='rg'>
            <h4 class='section-header'>Responsible Gaming</h4>
            <div class='responsible-gaming'>
              <img src='https://cdn.s777.club/web/rg1.svg'/>
              <img src='https://cdn.s777.club/web/rg2.svg'/>
              <img src='https://cdn.s777.club/web/rg3.svg'/>
            </div>
          </div>
        </CCol>
      </CRow>
    
    
    <div class='copyright'>Copyright &copy; {{new Date().getFullYear()}} Super 777 Club<br/>by MicroTech Gaming</div>

    <div v-if="$route.name == 'Home'">
      <div class='home-page-content'>
        <p>For those who are passionate about gambling, going to a casino and participating in it is their great wish. With the current era, players do not need to spend a lot of time, effort and money to move to the place to participate. The presence of S777.club has supported all problems, players just need to sit at home, log in and join. S777.club is a system that specializes in providing online fish table & slot games products in the USA market. S777.club online casino has good quality, full range of betting products from slot game to fish table to get real money. The system meets many new utilities, the large amount of bonuses is extremely attractive.</p>
        <h2>What Is Fish Table Game Online?</h2>
        <p>Fish table game online is an entertainment genre that uses real money to buy weapons and hunt creatures on the ocean floor. Players participating in <strong>fish table gambling</strong> will receive bonus points from creatures, convert bonus points into money and withdraw to bank accounts. That's why this type of bet is known as the <strong>fish table game online real money</strong>.</p>
        <p>This game is loved by many people because it is easy to play, easy to join, and suitable for many audiences. Especially with the online version of the fish table game, players can join online. That means players only need to use devices with an internet connection, download their favorite version and join.</p>
        <h2>Where To Play Fish Table Online & Slots Game?</h2>
        <p>Currently, there are many websites that offer Fish table online and slot game, but players must choose a good casino, ensuring the quality of products and betting services. If players believe, they can try the gambling experience at the website S777.club. Here, players do not have to worry, because the system is very safe, bringing the best products, in accordance with the needs and desires of the players' bets. The technical staff at S777.club combine with reputable game providers, creating the best quality slot games and online fish table products. S777.club has launched a survey for gambling game enthusiasts in the US, most of which give good reviews for the quality of the website's products and services. At the system, players can participate in all versions of the fish table game, the quality is guaranteed from format to content.</p>
        <h2>About S777.club</h2>
        <p><a href='https://s777.club'>S777.club</a> is a famous and legal online casino site in the USA, offer Fish table online and slot game. The player's participation in the betting system depends on the region the player is living in, because that place will have its own rules. S777.club allows players to participate in real money betting and receive real money rewards. Players just need to register an account, make a deposit and join the version they love. After the end of the bet, depending on each person, they can use the bonus to continue participating or withdraw the bonus to their personal bank account.</p>
        <p>With the desire to meet the entertainment needs of all players, S777.club does not only provide products on computers. Players can also use mobile phones to participate, even though the products are displayed on a smaller screen, the quality is still guaranteed. Players only need to use a previously registered account to participate with both skins.</p>
        <p>All player activities take place according to the website's automatic system. Players only need to deposit or withdraw money according to the methods provided by the website. The system links with many local vehicles and banks to serve the payment needs of players. At the same time, players' personal information and data are absolutely confidential and absolutely do not disclose information to individuals or third parties.</p>
        <h2>Is It Safe to Betting at S777.club?</h2>
        <p>S777.club is a licensed and licensed betting casino. Players can feel secure when participating in betting here. Just come to S777.club, players will experience the best environment. The player's information is saved and encrypted by the system with 228-bit SSL technology, ensuring absolute safety. In addition, the casino protects the privacy of its players by all means, makes use of all technologies and regularly conducts checks, detects, restricts and catches fraudsters immediately.</p>
        <h2>Reputable Betting Software Provider In USA?</h2>
        <p>All products at S777.club come from major software vendors, with high quality and safety. Such as: Microgaming, Joker Gaming, Play’n Go, Qtech,... The system owns professional technical staff, working enthusiastically, creating the most complete products before reaching the players. Especially casino fish game products with investment in 3D graphics, extremely realistic sound. Players in addition to entertainment after tiring working hours, rewards with great real money value are also one of the attractive things in the system.</p>
        <h2>How To Play Deposit At S777.club?</h2>
        <p>For depositing at S777.club, players can do it through bank transfer, bitcoin, Cash app, etc. Each method will have specific instructions and incentives provided by the system. grant. The level of support of each method is different. The system always ensures the interests of players when there is a problem in the transaction, all are fixed as quickly as possible.</p>
        <h2>How To Withdraw At S777.club</h2>
        <p>The most special at S777.club is when players join the <strong>fish table gambling</strong>, players receive bonuses and can withdraw to their personal accounts. The process is quick, as soon as the system authenticates successfully, the bet will be returned to the player's account. All information will be announced right at the player's main account. Just follow the required order, the withdrawal at S777.club will quickly succeed.</p>
        <h2>Can I Play Fish Table For Free?</h2>
        <p>Members of the S777.club website can join any version of the fish table gambling game online for free. Each version corresponds to new features, along with many attractive utilities. The games all have demo versions for players to experience before making a decision to bet with real money. As a result, players can accumulate more betting experience. In addition, each game has many levels, from easy to difficult for each group of players.</p>
        <h2>How To Win Money At Fish Tables?</h2>
        <p>Similar to the Fish tables online versions of other websites, S777.club allows players to use real money to place bets. This means that if you win, you will also receive real money as a reward. The way to win the online fish table game is not difficult, because almost all games have similar rules, players can catch a lot of loot or not depending on the level and practice.</p>
        <p>In addition to providing online <strong>fish shooting game real money</strong> products, S777.club also has detailed information about each game, instructions on how to use weapons, tips for participating in fish tables online. If players have time, they can refer to these strategies, they are very useful, it improves the player's ability to win, and brings great rewards.</p>
      </div>
    </div>
  </CContainer>
    
  </div>
</template>

<script>
import { EventBus } from '@/event-bus.js'

export default {
  name: 'TheFooter',
  methods:{
    openSupportModal(){
      EventBus.$emit('openSupportModal')
    },
    toggle (event) {
      if(event.target.classList){
        event.target.classList.toggle('active')
      }
      if(event.target.nextSibling){
        event.target.nextSibling.classList.toggle('active')
      }
    }
  }
}
</script>

<template>
  <div class='deposit-dialog' v-if='depositModal'>
    <CModal title='Make a Deposit' :fade='true' :backdrop='false' :closeOnBackdrop='false' :show.sync='depositModal'>

      <!--
      <div class='select-payment' v-show='!isCashappPendingLoading'>
        <div class='left-payment'>
          <div class='payment-type' v-bind:class='{ selected: isSelectedCashapp }' @click='selectCashapp'>
            <img src='https://cdn.s777.club/web/cashapp.png'/>
            <span>Cashapp</span>
          </div>
        </div>
        <div class='right-payment'>
          <div class='payment-type' v-bind:class='{ selected: isSelectedBitcoin }' @click='selectBitcoin'>
            <img src='https://cdn.s777.club/web/bitcoin.png'/>
            <span>Bitcoin</span>
          </div>
        </div>
      </div>
      -->

      <div class='justify-content-center dialog-spinner' v-show='isCashappPendingLoading'>
        <div class='spinner-border' role='status'>
          <span class='sr-only'>Loading...</span>
        </div>
      </div>

      <div class='btc-address-container' v-if='isSelectedBitcoin && !isCashappPendingLoading'>
        <p class='dialog-info'>
          <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-info-circle' viewBox='0 0 16 16'>
            <path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'/>
            <path d='m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z'/>
          </svg> &nbsp; 
          <span>Please send payment to the <strong>bitcoin (BTC)</strong> address shown below. Your game credits will be updated upon <strong>1 confirmation</strong> in the blockchain.</span>
        </p>

        <p class='btc-pending' v-if='pendingBtcDepositCount > 0'><span class='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span>{{pendingBtcDepositCount}} bitcoin {{pendingBtcText}} totaling {{pendingBtcDepositAmount | currency}} pending confirmation</p>

        <p class='btc-pending' v-if='pendingBtcDepositCount == 0'><span class='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span>Listening to wallet address..</p>

        <div class='btc-address'>
          <qrcode-vue :value='btcAddressLink' level='L' size='200' background='#251146' foreground='#ffffff' class=''/>
          <p class='btc-text-address'>{{btcAddress}}</p>
          <!--<p>Always <strong>verify the btc address</strong> before sending as all bitcoin transactions are <strong>non-reversible</strong>.</p>-->
        </div>
      </div>

      <div v-if='isSelectedCashapp'>
        <CForm @submit='submitCADepositForm' v-show='!isCashappPendingLoading && !cashAppPendingTxn && !cashAppSuccessTxn && !cashAppFailureTxn'>
          <p class='dialog-info'>
            <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-info-circle' viewBox='0 0 16 16'>
              <path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'/>
              <path d='m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z'/>
            </svg> &nbsp; 
            Please take a minute to read through <strong>our cashapp payment instructions</strong> before proceeding with payment transfer.
          </p>
          <CInput 
            v-model='name'
            placeholder='Enter your name on cashapp' 
            class='capitalize'
            invalidFeedback='Alphanumeric and spaces only'
            :isValid='validateName'
            v-bind:class='{ valid: isValidName }'
            required>
            <template #prepend-content><CIcon name='cil-user'/></template>
          </CInput>
          <CInput 
            v-model='cashtag'
            placeholder='Enter your cashtag (omit $ symbol)' 
            invalidFeedback='Alphanumeric only - $ symbol not required'
            :isValid='validateCashtag'
            v-bind:class='{ valid: isValidCashtag }'
            required>
            <template #prepend-content><CIcon name='cil-dollar'/></template>
          </CInput>
          <CInput 
            v-model='amount'
            placeholder='Enter deposit amount (min $10)' 
            invalidFeedback='Whole number only - min 10 - $ symbol not required'
            :isValid='validateAmount'
            v-bind:class='{ valid: isValidAmount }'
            required>
            <template #prepend-content><CIcon name='cil-money'/></template>
          </CInput>
          <div class='form-group form-actions'>
            <CButton block type='submit' class='signup-submit-btn' :disabled='formLoading'>
              <span class='spinner-border spinner-border-sm' v-if='formLoading'></span>
              Request for Cashtag
            </CButton>
          </div>
        </CForm>

        <!-- For PP / PV Status -->
        <div class='pending-cashapp' v-if='cashAppPendingTxn'>
          <p class='dialog-info'>
            <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-info-circle' viewBox='0 0 16 16'>
              <path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'/>
              <path d='m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z'/>
            </svg> &nbsp; 
            <span v-if="cashAppPendingTxn.status == 'PP'">Please make payment from your cashtag <strong>${{ cashAppPendingTxn.paymentDetail.senderCashtag }}</strong> to the cashtag provided below within 5 minutes:</span>
            <span v-if="cashAppPendingTxn.status == 'PV'">Thank you for the confirmation. Your payment is <strong>pending verification</strong> and will be processed within 3-5 minutes.</span>
          </p>
          <p class='tag' v-if='cashAppPendingTxn.paymentDetail'>
            <span class='amount'>${{ cashAppPendingTxn.paymentDetail.requestedAmount }}</span><br/>
            ${{ cashAppPendingTxn.paymentDetail.receiverCashtag }}
            <br/><small>( {{ cashAppPendingTxn.paymentDetail.receiverCashtagName }} )</small><br/><br/>
            <span class='txn' v-if='cashAppPendingTxn.paymentDetail'>
              Requested At: {{ cashAppPendingTxn.createdAt }}<br/>
              Reference ID: {{ cashAppPendingTxn.txnID }}
            </span>
          </p>
          <CButton block class='signup-submit-btn' @click='cashappPaid' :disabled='formLoading' v-if="cashAppPendingTxn.status == 'PP'">
            <span class='spinner-border spinner-border-sm' v-if='formLoading'></span>
            I've made payment
          </CButton>
          <CButton block type='submit' class='login-submit-btn' v-show="cashAppPendingTxn.status == 'PV'">
            <span class='spinner-border spinner-border-sm'></span>
            Verification in progress
          </CButton>
        </div>

        <!-- For Successful Status -->
        <div class='pending-cashapp' v-if='cashAppSuccessTxn'>
          <p class='dialog-info'>
            <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-info-circle' viewBox='0 0 16 16'>
              <path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'/>
              <path d='m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z'/>
            </svg> &nbsp; 
            <span>Your cashapp deposit payment was <strong>successful</strong> and your account has been credited with:</span>
          </p>
          <p class='tag'>
            <span class='amount'>${{cashAppSuccessTxn.amount}}</span>
            <br/><br/>
            <span class='txn'>
              Completed At: {{ cashAppSuccessTxn.date }}<br/>
              Reference ID: {{ cashAppSuccessTxn.txnID }}
            </span>
          </p>
          <CButton block class='signup-submit-btn'>Payment Complete</CButton>
        </div>

        <!-- For Failure Status -->
        <div class='pending-cashapp' v-if='cashAppFailureTxn'>
          <p class='dialog-info'>
            <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-info-circle' viewBox='0 0 16 16'>
              <path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'/>
              <path d='m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z'/>
            </svg> &nbsp; 
            <span>Your cashapp deposit payment was <strong>unsuccessful</strong>. If you have already made payment, a <strong>full refund will be issued</strong> immediately. You may proceed with a new payment attempt OR select an <strong>alternate payment</strong> method instead.</span>
          </p>
          <p class='tag'>
            <span class='amount'>${{cashAppFailureTxn.amount}}</span>
            <span class='void-reason'>
              <span v-if='cashAppFailureTxn.voidCode == 0'>Cashapp automated refund</span>
              <span v-if='cashAppFailureTxn.voidCode == 1'>Deposited less than minimum</span>
              <span v-if='cashAppFailureTxn.voidCode == 2'>Deposited using different tag</span>
              <span v-if='cashAppFailureTxn.voidCode == 3'>Non payment</span>
            </span>
            <br/>
            <span class='txn'>
              Completed At: {{ cashAppFailureTxn.date }}<br/>
              Reference ID: {{ cashAppFailureTxn.txnID }}
            </span>
          </p>
          <CButton block class='login-submit-btn'>Payment Failed</CButton>
        </div>
      </div>

      <div class='signup-help' v-if='!isCashappPendingLoading'>
        <CButton block variant='ghost' color='info' size='sm' @click='openSupportModal'><img src='https://cdn.s777.club/web/sidebar-contact.png' style='height:18px;'> Need help?</CButton>
      </div>

      <!-- Override default model footer using slot -->
      <template v-slot:footer-wrapper><div></div></template>

    </CModal>
  </div>
</template>

<script>
import apiDeposits from '@/api/deposits'
import { EventBus } from '@/event-bus.js'
import QrcodeVue from 'qrcode.vue'

export default {
  name: 'TheDepositDialog',
  computed: {
    btcAddress(){
      return window.localStorage.getItem('btcAddress') 
    },
    btcAddressLink(){
      return 'bitcoin:' + window.localStorage.getItem('btcAddress') 
    },
    isLoggedIn () {
      return this.$store.state.isLoggedIn
    }
  },
  components: {
    QrcodeVue
  },
  watch: {
    'depositModal': function() {
      if(!this.depositModal){
        this.toggleBodyClass('removeClass', 'noscroll');
        this.isCashappPendingLoading = false
        this.cashAppPendingTxn = null
        this.cashAppSuccessTxn = null
        this.cashAppFailureTxn = null
        this.isSelectedCashapp = false
        this.isSelectedBitcoin = true
      }
    }
  },
  data () {
    return { 
      formLoading: false,
      name: null,
      cashtag: null,
      amount: null,
      isValidName: true,
      isValidCashtag: true,
      isValidAmount: true,
      depositModal: false,
      isSelectedCashapp: false,
      isSelectedBitcoin: true,
      isCashappPendingLoading: true,
      cashAppPendingTxn: null,
      cashAppSuccessTxn: null,
      cashAppFailureTxn: null,
      pendingBtcDepositCount: 0,
      pendingBtcDepositAmount: 0,
      pendingBtcText: ''
    }
  },
  created () {
    EventBus.$on('openDepositModal', () => {
      this.openDepositModal()
    })
    EventBus.$on('caTxnSuccess', (txn) => {
      if(this.cashAppPendingTxn && this.cashAppPendingTxn._id == txn.id){
        this.cashAppSuccessTxn = txn
        this.cashAppPendingTxn = null
      }
    })
    EventBus.$on('caTxnFailure', (txn) => {
      if(this.cashAppPendingTxn && this.cashAppPendingTxn._id == txn.id){
        this.cashAppFailureTxn = txn
        this.cashAppPendingTxn = null
      }
    })
    EventBus.$on('btcTxnDetected', (txn) => {
      this.refreshLastBtcTxns()
    })
    EventBus.$on('btcTxnConfirmed', (txn) => {
      this.refreshLastBtcTxns()
    })
  },
  methods:{
    openDepositModal () {
      this.toggleBodyClass('addClass', 'noscroll');
      this.depositModal = true
      this.isCashappPendingLoading = true
      
      apiDeposits.getLastTxns().then(response => {
        if (response.data.status === 'success') {
          if(response.data.txnP) {
            this.cashAppPendingTxn = response.data.txnP
          }
          if(response.data.txnS) {
            this.name = response.data.txnS.paymentDetail.senderCashtagName
            this.cashtag = response.data.txnS.paymentDetail.senderCashtag
          }
          this.pendingBtcDepositCount = response.data.btcTxnCount
          this.pendingBtcDepositAmount = response.data.btcTxnAmount/100
          if(this.pendingBtcDepositCount == 1){
            this.pendingBtcText = 'deposit'
          }else{
            this.pendingBtcText = 'deposits'
          }
          this.isCashappPendingLoading = false
        }
      }).catch((err) => {
        this.formLoading = false
      })

    },
    openSupportModal (){
      this.depositModal = false
      EventBus.$emit('openSupportModal')
    },
    refreshLastBtcTxns(){
      apiDeposits.getLastTxns().then(response => {
        if (response.data.status === 'success') {
          this.pendingBtcDepositCount = response.data.btcTxnCount
          this.pendingBtcDepositAmount = response.data.btcTxnAmount/100
          if(this.pendingBtcDepositCount == 1){
            this.pendingBtcText = 'deposit'
          }else{
            this.pendingBtcText = 'deposits'
          }
        }
      }).catch((err) => {
      })
    },
    selectCashapp(){
      this.isSelectedCashapp = true;
      this.isSelectedBitcoin = false;
    },
    selectBitcoin(){
      this.isSelectedCashapp = false;
      this.isSelectedBitcoin = true;
    },
    validateName (val) {
      if(val === null){
        this.isValidName = true
      }else{
        if(val && /^[a-zA-Z0-9\s]+$/.test(val)){
          this.isValidName = true
          return true
        }else{
          this.isValidName = false
          return false
        }
      }
    },
    validateCashtag (val) {
      if(val === null){
        this.isValidCashtag = true
      }else{
        if(val && /^[a-zA-Z0-9]+$/.test(val)){
          this.isValidCashtag = true
          return true
        }else{
          this.isValidCashtag = false
          return false
        }
      }
    },
    validateAmount (val) {
      if(val === null){
        this.isValidAmount = true
      }else{
        if(val && /^\d+$/.test(val) && parseInt(val) >= 10){
          this.isValidAmount = true
          return true
        }else{
          this.isValidAmount = false
          return false
        }
      }
    },
    submitCADepositForm: function (e) {
      
      e.preventDefault()

      if(this.name === null){ this.name = '' }
      if(this.cashtag === null){ this.cashtag = '' }
      if(this.amount === null){ this.amount = '' }

      if(this.validateName(this.name) && this.validateCashtag(this.cashtag) && this.validateAmount(this.amount)){

        this.formLoading = true;
        
        apiDeposits.deposit(this.name, this.cashtag, this.amount).then(response => {
          this.formLoading = false
          if (response.data.status === 'success') {
            this.cashAppPendingTxn = response.data.txn
          }
          if (response.data.errorMsg) {
            this.$toast.error(response.data.errorMsg, {
              position: 'top-center',
              timeout: 5000,
              hideProgressBar: true
            })
          }
        }).catch((err) => {
            if (err.response.data.errorMsg) {
              this.$toast.error(err.response.data.errorMsg, {
                position: 'top-center',
                timeout: 5000,
                hideProgressBar: true
              })
            }
            this.formLoading = false
        })

      }

    },
    cashappPaid(){

      if(this.cashAppPendingTxn){
        this.formLoading = true

        apiDeposits.paid(this.cashAppPendingTxn._id).then(response => {
          if (response.data.status === 'success') {
            this.cashAppPendingTxn = response.data.txn
          }
          this.formLoading = false
        }).catch((err) => {
          this.formLoading = false
        })
      }

    },
    toggleBodyClass(addRemoveClass, className) {
      //window.scrollTo(0,0)
      const el = document.body
      if (addRemoveClass === 'addClass') {
        el.classList.add(className)
      } else {
        el.classList.remove(className)
      }
    }
  }
}
</script>